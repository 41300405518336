import {
  numbersTemp,
  statusColors,
  getFirstOrange,
  LineWinAnimationType,
} from "service";

export const shouldShowRollButtonOnLoad = (showRollButton, theme) => {
	if (theme === 'cricket') {
		showRollButton(false);
	}
}

export const rollDoneCallback = (
	source,
	sounds,
	result1,
	result2,
	rollResult,
) => {
	const { gameEvents } = source;
	gameEvents.once('execute-post-roll-done', () => {
		postRollDone(
			source,
			sounds,
			result1,
			result2,
			rollResult,
		);
	});
	gameEvents.emit('roll-api-done', result1 + result2);
};

export const postRollDone = (
	source,
	sounds,
	result1,
	result2,
	rollResult,
) => {
	const { gameSession: { strikeStreak, winnings } } = rollResult;
	const {
		setRolledNum,
		setStreakStrike,
		setBonus,
		startedRoundRef,
		isPlayingSound,
		onClickRollBtn,
		setShowAnimationStrike,
		rollCnt,
		setRollCnt,
		setDisplayOuts,
		setHoleTimes,
		holeTimesRef,
		setIsGameOver,
		statusesRef,
		setStatuses,
		secondStrikeRef,
		rollButtonEnabled,
		showRollButton,
		isAutoRef,
		timeoutRef,
		onClickNumber,
		rollCntRef,
		freeGameAwardedRef,
		setSkippingCount,
		skippingCount,
		skippingRef,
		setSkipping,
		setIsRolling,
		waitingForRoll,
		acceptedSideBetRef,
		newWinGroupKeys,
		setNewWinGroupKeys,
	} = source;

	let sumDices = result1 + result2;

	if (sumDices === 7) {
		setShowAnimationStrike(true);
		rollCnt < 15 && setRollCnt(0);
	}
	setRolledNum(sumDices);
	let show777Animation = false;

	if (sumDices === 7) {
		setStreakStrike(strikeStreak);
		if (strikeStreak === 3) {
			/*
			 * SB-245: Remove 777 bonus temporarily
			*/
			// setShow777(true);
			show777Animation = true;
			// setShowWinAnimation(true);
			setBonus(winnings);
			isPlayingSound && sounds.playSoundWin();
		}
	} else {
		setStreakStrike(0);
	}

	setDisplayOuts(false);
	if (startedRoundRef.current) {
		if (sumDices === 7) {
			setHoleTimes(holeTimesRef.current + 1);

			if (holeTimesRef.current < 3) {
				setDisplayOuts(true);
			}

			if (holeTimesRef.current === 3) {
				setTimeout(() => {
					setIsGameOver(true);
				}, !show777Animation ? 1000 : 4000);
			} else {
				const newStatuses = statusesRef.current.map((_status, _i) => {
					if (
						numbersTemp[_i] === sumDices &&
						_status == statusColors.white
					) {
						return 3;
					}
					return _status;
				})

				setStatuses(newStatuses);

				if (holeTimesRef.current === 2) {
					secondStrikeRef.current = setTimeout(() => {
						if (!rollButtonEnabled) {
							onClickRollBtn();
						} else {
							showRollButton(true);
						}
					}, 2000);
				}

				if (isAutoRef.current) {
					if (holeTimesRef.current !== 2) {
						timeoutRef.current = setTimeout(() => {
							if (isAutoRef.current) {
								onClickNumber(getFirstOrange(statusesRef.current));
							}
						}, 2000);
					}
				}
			}
		} else {
			const newStatuses = statusesRef.current.map((_status, _i) => {
				if (numbersTemp[_i] === sumDices && _status == statusColors.white) {
					return 3;
				}

				return _status;
			})
			setStatuses(newStatuses);

			if (rollCntRef.current !== 15 || freeGameAwardedRef.current) {
				if (
					getRemainNums(sumDices, statusesRef).length > 0 // if you have white number
				) {
					isPlayingSound && sounds.playSoundOrangeTile();
					if (isAutoRef.current) {
						timeoutRef.current = setTimeout(
							() => {
								if (isAutoRef.current) {
									onClickNumber(getFirstOrange(statusesRef.current));
								}
							},
							2000
						);
					}
				} else {
					setSkippingCount(skippingCount + 1);
					skippingRef.current = true;
					setSkipping(true);
				}
			}
		}
	}

	setIsRolling(false);
	waitingForRoll.current = false;
	acceptedSideBetRef.current = undefined;
	newWinGroupKeys.length > 0 && setNewWinGroupKeys([]);
}

export const getRemainNums = (num, statusesRef) => {
	return statusesRef.current.filter(
		(_status, _i) =>
			numbersTemp[_i] === num &&
			(_status == statusColors.white || _status == statusColors.orange)
	);
};

export const handleApiRollDone = (source, rollResult, handleSideBetWinAnimation) => {
	const {
		gameEvents,
		gameEngineStateRef,
		rollCntRef,
		sideBet,
		setGameEngineState,
		setRollCnt,
		setShowSideBetWin,
		setSideBet,
		freeGameAvailable
	} = source;

	gameEvents.once('pitch-animation-done', () => {
		gameEngineStateRef.current = rollResult;
		setGameEngineState(rollResult);

		if (rollResult.gameSession && rollResult.gameSession.gameState !== 'game-over-free') {
			if (rollResult.gameSession.rollStreakCounter && rollCntRef.current < 15 && freeGameAvailable) {
				setRollCnt(rollResult.gameSession.rollStreakCounter);
			}
		}

		if (sideBet !== undefined) {
			setShowSideBetWin(sideBet.id);
			setTimeout(() => {
				setShowSideBetWin(null);
			}, 1000);
		}

		setSideBet(undefined);
		handleSideBetWinAnimation(rollResult);
		gameEvents.emit('execute-post-roll-done');
	});
}

export const shouldTriggerIntroAnimation = (
	fromBetScreen,
	gameEvents,
	gameEngineState,
	showRollButton,
	sideBetsEnabled,
	setSideBetsIntro
	) => {
	if (fromBetScreen.current) {
		setSideBetsIntro(true)
		setTimeout(() => {
			console.log("Emitting animate-ball-intro");
			gameEvents.emit('animate-ball-intro');
			if (sideBetsEnabled) {
				gameEvents.emit('side-bet-intro-check');
			}
		}, 3000);
	} else if (gameEngineState.gameSession && gameEngineState.gameSession.gameState === 'needs-to-roll') {
		showRollButton(true);
	}
}

export const showSidebetPopup = () => {}


export const sideBetIntro = ({ gameEvents, setSideBetsEnabled }) => {
		gameEvents.on('side-bet-intro-check', () => {
		setSideBetsEnabled(false)
	});
}
export const thirdStrikeTrans = () => {
	return "common.cricket.3rdStrike"
}

export const playTripleSevenSound = (sounds, isPlayingSound) => {
	isPlayingSound && sounds.playTripleSevenLastStrike()
}

export const getSkippingTime = (initial) => {
	if (initial) {
		return 2500;
	}

	return 2000;
}

export const setLineWin = ({
	winnings,
	setShowLineWinAnimation,
	gameOverTimeoutRef,
	handleGameOverTimeout,
	setShowGameOverBanner,
	streakStrike,
	holeTimes,
	freeCnt,
	winnedLinesCnt,
}) => {
	// if (
	// 	(streakStrike === 3 && streakStrike === holeTimes)
	// 	|| winnings <= 0
	// 	|| winnedLinesCnt === 2) {
	// 	setShowGameOverBanner(true);
	// 	gameOverTimeoutRef.current = setTimeout(handleGameOverTimeout, 2000);
	// 	return
	// }
	if (
		(streakStrike === 3 && streakStrike === holeTimes)
		|| winnings <= 0) {
		setShowGameOverBanner(true);
		gameOverTimeoutRef.current = setTimeout(handleGameOverTimeout, 2000);
		return
	}
	setShowLineWinAnimation(LineWinAnimationType.FullCard);
	setTimeout(() => {
		setShowLineWinAnimation(LineWinAnimationType.Hide);
		gameOverTimeoutRef.current = setTimeout(handleGameOverTimeout, 2000);
	}, 11000);
}

export const sideBetToggle = ({ setRollButtonEnabled, val }) => {
	setRollButtonEnabled(val)
}

export const suspenseSound = ({ isPlayingSound, playSuspense777, streakStrike, holeTimes  }) => {
	if (streakStrike == 2 && holeTimes == 1 ) {
		isPlayingSound && playSuspense777();
	}
}

export const stopSuspenseSound = ({
	stopSuspense777,
	streakStrike
}) => {
	if (stopSuspense777) {
		stopSuspense777();
	}
}

export const handleOnSelectBet = ({ setSideBet, item }) => {
	setSideBet(item)
}

export const sideBetUndefined = () => {}

export const autoPickResetSideBet = ({setSideBet, isAuto}) => {
	if (isAuto) {
		setSideBet(undefined);
	}
}

export const setSideBetsEnabled = ({ setSideBetsEnabled }) => {
	setSideBetsEnabled(false)
}

export const enableSideBets = ({ setSideBetsEnabled }) => {
	setSideBetsEnabled(true)
}


export const getNumbers = () => {
	const numbers = [
		1,44,50,55,29,55,33,10,30,50,22,49,7,1,49,49,10,30,50,44,50,55,29,33,22
	];

	return numbers;
}

export const playThemeSoundGreenLine = ({ isPlayingSound, sounds }) => {
	isPlayingSound && sounds.playSoundGreenLine();
}

export const playOrganSound = (audioRef, n) => {}

export const stopOrganSound = (audioRef) => {}

export const playThemeAnthem = (audioRef) => {}

export const stopThemeAnthem = (audioRef) => {}

export const handleStartWinAnimation = () => {
	const starExplosionAnimation = window.playStarExplosion();

	setTimeout(() => {
		if (starExplosionAnimation) {
			const animationState = starExplosionAnimation.state.states['MainState'];
			const emitters = animationState.emitters;
			emitters.forEach(emitter => {
				emitter.on = false;
			})
		}
	}, 6000);
}
export const handleCustomBgSound = () => {}

export const playOrangeClickSound = ({play, streakStrike, rolledNum}) => {
	if (streakStrike === 0 && rolledNum === 7) {
		play();
	}
}

export const getCenterTileImage = (status) => {
}

export const onFilled = ({
	filled,
	isPlayingSound,
	playJackpotSound,
	playSwooshSound,
	setFreeCnt,
	freeCnt,
	setShowFreeCelebration,
	rollCntRef,
	setFreeGameAwarded,
	setFilled,
	onClickNumber,
	getFirstOrange,
	statuses,
	skippingRef,
	setSkipping,
	setSkippingCount,
	skippingCount,
	moveRoundBonusDelay,
	rolledNumRef,
	statusesRef,
	isAuto,
}) => {
	if (filled) {
		isPlayingSound && playJackpotSound();
		setTimeout(() => {
			isPlayingSound && playSwooshSound();
		}, 4500);
		setFreeCnt(freeCnt + 1);
		setShowFreeCelebration(true);
		const timer = setTimeout(
			() => {
				if (rollCntRef.current === 15) {
					setFreeGameAwarded(true);
				}

				setFilled(false);
				if (getRemainNums(rolledNumRef.current, statusesRef).length > 0) {
					if (isAuto) {
						onClickNumber(getFirstOrange(statuses));
					}
				} else {
					skippingRef.current = true;
					setSkipping(true);
					setSkippingCount(skippingCount + 1);
				}
			},
			moveRoundBonusDelay
		);
		return () => clearTimeout(timer);
	}
}

export const onRollCount = ({
	rollCntRef,
	setFilled,
}) => {
	if (rollCntRef.current === 15) {
		setFilled(true);
	}
}

export const onFreeGameAwarded = () => {}